import App from 'antd/lib/app';
import { useCallback, useEffect, useRef } from 'react';

import { API } from '../api';
import { useAppDispatch } from '../state';
import { useUsersListSelector } from '../state/users/selectors';
import {
  setUsersListAction,
  setUsersListError,
  setUsersListLoading,
} from '../state/users/usersListSlice';

type THookResponse = {
  handleLoadUsers: () => Promise<void>;
} & ReturnType<typeof useUsersListSelector>;

export const useLoadUsers: () => THookResponse = () => {
  const dispatch = useAppDispatch();
  const { loading, loaded, data, errorOnLoad } = useUsersListSelector();
  const loadingRef = useRef(loading);

  const { message } = App.useApp();

  useEffect(() => {
    loadingRef.current = loading;
  }, [loading]);

  const handleLoadUsers = useCallback(async () => {
    if (!loadingRef.current) {
      dispatch(setUsersListLoading(true));
      try {
        const response = await API.users.getAll();
        dispatch(
          setUsersListAction(
            response.data.sort(
              (a, b) =>
                new Date(a.createdAt).getUTCDate() -
                new Date(b.createdAt).getUTCDate()
            )
          )
        );
        loadingRef.current = false;
      } catch (e) {
        message.error('Ошибка при загрузке пользователей');
        dispatch(setUsersListError(true));
        dispatch(setUsersListLoading(false));
        loadingRef.current = false;
      }
    }
  }, []);

  useEffect(() => {
    if (!loading && !loaded && !loadingRef.current && !errorOnLoad) {
      setTimeout(() => {
        void handleLoadUsers();
      }, 0);
    }
  }, [loaded, loading, errorOnLoad]);

  return { data, loading, errorOnLoad, handleLoadUsers, loaded };
};
